var widget;
var gRecaptchaResponse,
    verifyCallback = function (response) {
        gRecaptchaResponse = response;
    };
var onloadCallback = function () {
    var grecaptchaSize;
    if (window.innerWidth < 768) {
        grecaptchaSize = 'normal';
    } else {
        grecaptchaSize = 'compact';
    }
    widget = grecaptcha.render('grecaptcha', {
        'sitekey': '6LeBwi8UAAAAAJl7l8IDhyPIqmc9KiDm7q_hty82',
        'callback': verifyCallback,
        'theme': 'dark',
        'size': grecaptchaSize
    });
};