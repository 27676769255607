(function ($) {

    var field;
    var form = {
        initDataLayer: function () {
            dataLayer.push({
                'event': 'Kontakt'
            });
        },
        add: function () {
            var html = '';
            field = [{
                id: 'phone',
                label: 'Leave your phone number and we will call you back',
                maxlength: 30,
                regexp: new RegExp('^[0-9-+() ]+$'),
                required: true,
                tag: 'input',
                type: 'tel'
            }];

            html += '<form action="phpmailer/mail.php" method="POST">';

            for (var i = 0; i < field.length; i++) {
                var fieldId = field[i].id,
                    fieldLabel = field[i].label,
                    fieldType = (field[i].type !== '') ? 'type="' + field[i].type + '"' : '',
                    fieldTag = field[i].tag,
                    fieldMaxLength = (field[i].maxlength !== '') ? 'maxlength="' + field[i].maxlength + '"' : '';

                html += '<label for="' + fieldId + '">' + fieldLabel + '</label>';
                html += '<' + fieldTag + ' ' + fieldType + ' ' + fieldMaxLength + ' name="' + fieldId + '" id="' + fieldId + '" class="inputPhone">' + ((field[i].tag !== 'input') ? '</' + field[i].tag + '>' : '');
            }

            html += '<div id="grecaptcha" class="grecaptcha"></div>';
            html += '<div id="notice" class="notice"></div>';
            html += '<input class="submit" type="submit" value="Leave your number">';
            html += '</form>';

            $('#contact-form').append(html);
        },
        validation: function (formObj, field) {
            var noErrors = true,
                patternStatus;
            $('.validation-notice').remove();

            function checkFieldEmpty(id, regexp) {
                patternStatus = regexp.test($('#' + id).val());
                if ($('#' + id).val() === '') {
                    $('#' + id).after('<span class="validation-notice">Field is required.</span>');

                    if (noErrors === true) {
                        noErrors = patternStatus;
                    }

                } else {
                    checkFieldPattern(id, regexp);
                }
            }

            function checkFieldPattern(id, regexp) {
                patternStatus = regexp.test($('#' + id).val());
                if ($('#' + id).val() !== '' && patternStatus === false) {
                    $('#' + id).after('<span class="validation-notice">Required valid field value.</span>');

                    if (noErrors === true) {
                        noErrors = patternStatus;
                    }

                }
            }

            for (var i = 0; i < field.length; i++) {
                if (field[i].required === true) {
                    checkFieldEmpty(field[i].id, field[i].regexp);
                } else if (field[i].required === false) {
                    checkFieldPattern(field[i].id, field[i].regexp);
                }
            }

            if (noErrors === true) {
                this.xhr(formObj);
            }
        },
        xhr: function (formObj) {
            $.ajax({
                    url: 'phpmailer/mail.php',
                    type: 'POST',
                    data: formObj.serialize()
                })
                .done(function (response) {
                    form.xhrstatus(response, formObj);
                })
                .fail(function (response) {
                    form.xhrstatus(response);
                });
        },
        xhrstatus: function (msg, formObj) {
            if (msg === 'phpmailer-success') {
                $('#notice').text('Your message passed successfully. Thank you!').addClass('success');
                // Google Tag Manager Data Layer
                this.initDataLayer();
                // Form and reCaptcha reset
                grecaptcha.reset();
                formObj[0].reset();
            } else if (msg === 'phpmailer-error') {
                $('#notice').text('Your message has not been sent. Please try again later.');
            } else if (msg === 'grecaptcha-false') {
                $('#notice').text('I\'m not a robot').removeClass('success');
            }
        }
    }

    form.add();

    $('body').on('submit', '#contact-form > form', function (event) {
        event.preventDefault();
        form.validation($(this), field);
    });

}(jQuery));